<template>
  <div class="my-fp-videos">
    <div class="container fluid">
      <fd-tabs v-model="tab">
        <!-- Pending -->
        <fd-tab-window name="Pending" card>
          <my-video-table status="Pending Approval"></my-video-table>
        </fd-tab-window>
        <!-- Approved -->
        <fd-tab-window name="Approved">
          <my-video-table status="Approval"></my-video-table>
        </fd-tab-window>
        <!-- Rejected -->
        <fd-tab-window name="Rejected">
          <my-video-table status="Reject"></my-video-table>
        </fd-tab-window>
      </fd-tabs>
    </div>
  </div>
</template>

<script>
export default {
  name: "MyFloorPlanVideo",
  components: {
    MyVideoTable: () =>
      import("@/components/ManageFloorPlanVideos/MyVideoTable")
  },
  mixins: [],
  props: {},
  data: function () {
    return {
      tab: ""
    };
  },
  computed: {},
  watch: {},
  created: function () {},
  beforeDestroy: function () {},
  mounted: function () {
    this.init();
  },
  methods: {
    async init() {},

    openVideoDetail(event) {
      //
    }
  }
};
</script>

<style lang="scss">
.my-fp-videos {
}
</style>
